var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormReservation"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end py-50"
  }, [_c('b-button', {
    attrs: {
      "variant": _vm.isHideFunction ? 'outline-danger' : 'danger',
      "size": "sm"
    },
    on: {
      "click": _vm.toggleHideFunction
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(_vm.isHideFunction ? 'showFeature' : 'hideFeature'))) + " ")])], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_vm.selectedTrip ? _c('booking-trip-detail', {
    attrs: {
      "selected-trips": _vm.selectedTrip,
      "is-create-group-booking": _vm.isCreateGroupBooking
    }
  }) : _vm._e(), _vm.isCombination ? _c('BookingPriceDetailCombination', {
    attrs: {
      "selected-trip": _vm.selectedTrip,
      "data-search-flight": _vm.getSearchFlight,
      "passenger-data-to-add": _vm.sortedPassengers,
      "ancillary-data": _vm.ancillaryData
    },
    on: {
      "update:passengerDataToAdd": function updatePassengerDataToAdd($event) {
        _vm.sortedPassengers = $event;
      },
      "update:passenger-data-to-add": function updatePassengerDataToAdd($event) {
        _vm.sortedPassengers = $event;
      },
      "handle-service": _vm.handleService,
      "handle-delete-service": _vm.handleDeleteService
    }
  }) : _vm.getSearchFlight && _vm.selectedTrip && _vm.sortedPassengers && !_vm.isCreateGroupBooking ? _c('booking-price-detail', {
    style: _vm.isShowPaymentCardLast ? 'order: 1' : '',
    attrs: {
      "selected-trip": _vm.selectedTrip,
      "data-search-flight": _vm.getSearchFlight,
      "passenger-data-to-add": _vm.sortedPassengers,
      "ancillary-data": _vm.ancillaryData
    },
    on: {
      "update:passengerDataToAdd": function updatePassengerDataToAdd($event) {
        _vm.sortedPassengers = $event;
      },
      "update:passenger-data-to-add": function updatePassengerDataToAdd($event) {
        _vm.sortedPassengers = $event;
      },
      "handle-service": _vm.handleService,
      "handle-delete-service": _vm.handleDeleteService
    }
  }) : _vm._e(), _vm.getSearchFlight && !_vm.isEmpty(_vm.selectedTrip) ? _c('booking-passenger-detail', {
    attrs: {
      "customer-id": _vm.customerId,
      "passenger-data-to-add": _vm.sortedPassengers,
      "data-search-flight": _vm.getSearchFlight,
      "selected-trip": _vm.selectedTrip,
      "is-create-group-booking": _vm.isCreateGroupBooking,
      "pax-data-group-booking": _vm.paxDataGroupBooking
    },
    on: {
      "update:passengerDataToAdd": function updatePassengerDataToAdd($event) {
        _vm.sortedPassengers = $event;
      },
      "update:passenger-data-to-add": function updatePassengerDataToAdd($event) {
        _vm.sortedPassengers = $event;
      },
      "deletePassenger": _vm.handleDeletePassenger,
      "multipleQuantityPaxChange": _vm.handleMultipleQuantityPaxChange
    }
  }) : _vm._e(), _vm.sortedPassengers && _vm.selectedTrip && !_vm.isCreateGroupBooking && (_vm.selectedTrip.some(function (trip) {
    return _vm.sourcesCanBuyAncillary.includes(trip.source) && !['INTERNAL'].includes(trip.clientId);
  }) || _vm.selectedTrip.some(function (trip) {
    return _vm.sourcesCanBuySeatMap.includes(trip.source) && !['INTERNAL'].includes(trip.clientId);
  })) ? _c('booking-addons-detail', {
    attrs: {
      "ssr-bags": _vm.ssrBags,
      "ssr-others": _vm.ssrOthers,
      "seat-map-data": _vm.seatMapData,
      "selected-trip": _vm.selectedTrip,
      "passenger-data-to-add": _vm.sortedPassengers,
      "is-combination": _vm.isCombination
    },
    on: {
      "update:passengerDataToAdd": function updatePassengerDataToAdd($event) {
        _vm.sortedPassengers = $event;
      },
      "update:passenger-data-to-add": function updatePassengerDataToAdd($event) {
        _vm.sortedPassengers = $event;
      }
    }
  }) : _vm._e(), _c('BCardGroup', {
    staticClass: "mb-50 mb-md-1",
    staticStyle: {
      "order": "2"
    },
    attrs: {
      "deck": ""
    }
  }, [_c('BookingAgencyDetail', {
    attrs: {
      "agency-data": _vm.agencyData,
      "checkbox-customer-data-replace-agency-data": _vm.checkboxCustomerDataReplaceAgencyData,
      "is-role-f1": _vm.isRoleF1,
      "enable-send-employee-mail-airline": _vm.enableSendEmployeeMailAirline,
      "check-send-employee-mail-airline": _vm.checkSendEmployeeMailAirline,
      "is-collapsed": _vm.isCollapsedAgencyAndContactDetail
    },
    on: {
      "update:agencyData": function updateAgencyData($event) {
        _vm.agencyData = $event;
      },
      "update:agency-data": function updateAgencyData($event) {
        _vm.agencyData = $event;
      },
      "update:checkboxCustomerDataReplaceAgencyData": function updateCheckboxCustomerDataReplaceAgencyData($event) {
        _vm.checkboxCustomerDataReplaceAgencyData = $event;
      },
      "update:checkbox-customer-data-replace-agency-data": function updateCheckboxCustomerDataReplaceAgencyData($event) {
        _vm.checkboxCustomerDataReplaceAgencyData = $event;
      },
      "update:checkSendEmployeeMailAirline": function updateCheckSendEmployeeMailAirline($event) {
        _vm.checkSendEmployeeMailAirline = $event;
      },
      "update:check-send-employee-mail-airline": function updateCheckSendEmployeeMailAirline($event) {
        _vm.checkSendEmployeeMailAirline = $event;
      },
      "toggleCollapse": _vm.toggleIsCollapsedAgencyAndContactDetail
    }
  }), _c('BookingContactDetail', {
    attrs: {
      "customer-data": _vm.customerData,
      "checkbox-customer-data-replace-agency-data": _vm.checkboxCustomerDataReplaceAgencyData,
      "is-role-f3": _vm.isRoleF3,
      "is-collapsed": _vm.isCollapsedAgencyAndContactDetail
    },
    on: {
      "update:customerData": function updateCustomerData($event) {
        _vm.customerData = $event;
      },
      "update:customer-data": function updateCustomerData($event) {
        _vm.customerData = $event;
      },
      "update:checkboxCustomerDataReplaceAgencyData": function updateCheckboxCustomerDataReplaceAgencyData($event) {
        _vm.checkboxCustomerDataReplaceAgencyData = $event;
      },
      "update:checkbox-customer-data-replace-agency-data": function updateCheckboxCustomerDataReplaceAgencyData($event) {
        _vm.checkboxCustomerDataReplaceAgencyData = $event;
      },
      "resetAgencyData": _vm.resetAgencyData,
      "toggleCollapse": _vm.toggleIsCollapsedAgencyAndContactDetail
    }
  })], 1)], 1), !_vm.isHideFunction ? _c('div', {
    staticClass: "position-sticky",
    staticStyle: {
      "bottom": "0",
      "z-index": "18"
    }
  }, [_c('BRow', {
    staticClass: "bg-white py-1 rounded-lg"
  }, [_c('BCol', {
    class: "".concat(_vm.isMobileView ? 'mb-50' : '', " d-flex-center"),
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_vm.isShowCheckBoxSplitItineraries ? _c('BFormCheckbox', {
    attrs: {
      "disabled": _vm.isDisableSplitItineraries
    },
    model: {
      value: _vm.isSplitItineraries,
      callback: function callback($$v) {
        _vm.isSplitItineraries = $$v;
      },
      expression: "isSplitItineraries"
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder font-medium-2"
  }, [_vm._v(_vm._s(_vm.$t('flight.splitItineraries')))])]) : _vm._e()], 1), _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('div', {
    class: "".concat(_vm.isMobileView ? 'w-100 d-flex-between' : 'd-flex-center')
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    class: "px-75 px-md-2 ".concat(!_vm.isMobileView ? 'mr-2' : ''),
    attrs: {
      "variant": "outline-secondary",
      "pill": ""
    },
    on: {
      "click": _vm.handleCancel
    }
  }, [_c('span', {
    staticClass: "align-middle text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.Back')) + " ")])]), _vm.isVisiblePayLater ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "px-75 px-sm-2",
    attrs: {
      "variant": "warning",
      "pill": "",
      "disabled": !_vm.canAccess('booking.createBooking')
    },
    on: {
      "click": function click($event) {
        return _vm.handleValidateBooking(_vm.PAY_LATER);
      }
    }
  }, [_c('span', {
    staticClass: "align-middle text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.createBooking')) + " ")])]) : _vm._e(), _vm.isMobileView && _vm.isVisiblePayNow && !_vm.isSplitItineraries ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "px-75 px-md-2",
    attrs: {
      "variant": "outline-danger",
      "pill": "",
      "disabled": !_vm.canAccess('booking.createBooking')
    },
    on: {
      "click": function click($event) {
        return _vm.handleValidateBooking(_vm.PAY_NOW);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.paynow')) + " ")]) : _vm._e()], 1)])], 1), !_vm.isMobileView ? _c('BCol', {
    staticClass: "d-flex-center",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_vm.isVisiblePayNow && !_vm.isSplitItineraries ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-danger",
      "pill": "",
      "disabled": !_vm.canAccess('booking.createBooking')
    },
    on: {
      "click": function click($event) {
        return _vm.handleValidateBooking(_vm.PAY_NOW);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.paynow')) + " ")]) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1) : _vm._e(), _c('CreateBookingConfirmPriceModal', {
    attrs: {
      "booking-data": _vm.bookingDataToAdd
    },
    on: {
      "create-booking": _vm.createBookingHandle
    }
  }), _c('ConfirmHoldBooking', {
    attrs: {
      "booking-data": _vm.bookingDataToAdd,
      "is-split-itineraries": _vm.isSplitItineraries
    },
    on: {
      "update:isSplitItineraries": function updateIsSplitItineraries($event) {
        _vm.isSplitItineraries = $event;
      },
      "update:is-split-itineraries": function updateIsSplitItineraries($event) {
        _vm.isSplitItineraries = $event;
      },
      "create-booking": _vm.createBookingHandle
    }
  }), _c('ModalShowGroupBooking', {
    attrs: {
      "data-group-booking": _vm.dataGroupBooking
    }
  }), _c('ErrorLengthPassengerQHModal', {
    attrs: {
      "error-lists": _vm.errLengthNameList,
      "pay-type": _vm.payType
    },
    on: {
      "handleCreateBooking": _vm.modifyNameToCreateBooking
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }